.page_dashboard {
  .ant-card-body {
    padding: 05px 05px 05px 05px !important;
  }

  .ant-row > .ant-col {
    margin-bottom: 15px !important;
  }

  card_info_wrapper {
    height: 60px;
    width: 600px !important;
  }

  .ant-card {
    border-radius: 14px;
    line-height: 1;
  }
}
