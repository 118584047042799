.custom_avatar {
  border-radius: 50%;
}
.card_info_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  .card_center_row {
    flex: 4;
    display: flex;
    flex-direction: column;
    .card_title {
      font-size: 1rem;
      font-weight: 500;
      color: #212121;
    }

    .count_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #757575;
      padding-top: 10px;
      font-weight: 400;
      .done {
        flex: 1;
        font-size: 0.8rem;
      }
      .pending {
        flex: 1;
        font-size: 0.8rem;
      }
    }
  }

  .total_count {
    flex: 1;
    font-size: 1.2rem;
    font-weight: 400;
    color: #757575;
    text-align: center;
  }
}
