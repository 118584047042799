.login_logo {
  width: 280px;
  height: 100% !important;
  border-radius: 15px;
  /* Adjust the value to get the desired roundness */
  border: 2px solid #ccc;
  /* Border color and thickness */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* X-offset, Y-offset, blur radius, and color */
  padding: 20px;
  /* Optional: Add padding inside the box */
  background-color: #fff;
  /* Optional: Background color */

}

.flag_img {
  width: 200px;
  height: 100% !important;

}

.main_right {
  width: 100% !important;
}


.time_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  // width: 400px!important;

  .left {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // background-color: #F5F5F5;
    padding: 10px 10px 10px 10px;
    margin: 10px 10px 10px 10px;
    border-radius: 15px;
    /* Adjust the value to get the desired roundness */
    border: 2px solid #ccc;
    /* Border color and thickness */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* X-offset, Y-offset, blur radius, and color */
    padding: 20px;
    /* Optional: Add padding inside the box */
    background-color: #fff;
    /* Optional: Background color */

    // border-right: 2px solid #0a8fdc;
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // background-color: #F5F5F5;
    padding: 10px 10px 10px 10px;
    margin: 10px 10px 10px 10px;
    // border-radius: 25px;
    border-radius: 15px;
    /* Adjust the value to get the desired roundness */
    border: 2px solid #ccc;
    /* Border color and thickness */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* X-offset, Y-offset, blur radius, and color */
    padding: 20px;
    /* Optional: Add padding inside the box */
    background-color: #fff;
    /* Optional: Background color */


  }

  .country_name {
    font-size: 45px;
    text-align: center;
    font-weight: 600;
    // font-family: Cursive;
  }

  .clock_wrapper {
    font-weight: 400;
    width: 100%;
    text-align: center;
    color: #000000;
    // font-family: Cursive;

    padding: 1rem;

    .time {
      font-size: 1.5rem;
    }

    .date {
      font-size: 1.5rem;
    }

    .day {
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 640px) {
  .time_wrapper {
    .clock_wrapper {
      .time {
        font-size: 1.2rem;
      }

      .date {
        font-size: 1.2rem;
      }

      .day {
        font-size: 1.2rem;
      }
    }
  }
}