.monthly_card_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .card_info {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .user_name {
        font-size: 0.8rem;
        font-weight: 400;
        color: #212121;
      }
      .btn_booking_or_enquiry_count {
        width: 90px;
        background-color: #ffffff;
        border: 1px solid;
        border-color: #0a8fdc;
        border-radius: 4px;
        height: 36px;
        font-size: 1rem;
        font-weight: 500;
        color: #0a8fdc;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
