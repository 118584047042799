.payment_card_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .card_header {
    text-align: center;
    .total {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .card_info {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    .left {
      flex: 1;
    }
    .right {
      flex: 1;
    }
    .payment_item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1.2rem 0;
      .payment_value {
        font-size: 1rem;
        font-weight: 500;
        color: #212121;
      }
      .payment_type {
        font-size: 0.8rem;
        font-weight: 400;
        color: #757575;
      }
    }
  }
}
